import { DateTime, LocaleOptions, DateTimeFormatOptions } from 'luxon'

export function fromIsoToString(
  isoDate: string,
  format?: LocaleOptions & DateTimeFormatOptions
) {
  return DateTime.fromISO(isoDate).toLocaleString(format)
}

export const DATE_WITH_TIME = 'yyyy-MM-dd, T'
export function formatDateWithTime(isoDate: string, format = DATE_WITH_TIME) {
  return DateTime.fromISO(isoDate).toFormat(format)
}

export function getDate(isoDate: string) {
  return DateTime.fromISO(isoDate).toFormat('yyyy-MM-dd')
}

export function getTime(isoDate: string) {
  return DateTime.fromISO(isoDate).toFormat('T')
}

/*
  Generate an array of hours in format `hh:mm:ss`
*/
export function generateHours(withHalfHour = false) {
  const hoursArray = []

  for (let hour = 0; hour < 24; hour++) {
    const padded = (hour < 10 ? '0' : '') + hour

    hoursArray.push(`${padded}:00:00`)
    if (withHalfHour) hoursArray.push(`${padded}:30:00`)
  }

  return hoursArray
}

export function generateYears(minDelta = 100): number[] {
  const max = DateTime.now().year
  const min = max - minDelta

  const years: number[] = []

  for (let year = max; year >= min; year--) {
    years.push(year)
  }

  return years
}
