import { reactive, watch } from 'vue'
import { useRouter } from 'vue-router'

import { Primitive } from '@/models/common'

import { toLocationQuery } from '@/helpers/query'

type PossibleKey = string | number

export type PossibleParam = Primitive | NonNullable<Primitive>[]
export type PossibleParams = Record<PossibleKey, PossibleParam>
export type PossibleParamsNonNullable = Record<
  PossibleKey,
  NonNullable<PossibleParam>
>

function useQueryParams<T extends PossibleParams>(initialValue: T) {
  const router = useRouter()

  const paramsReactive = reactive<T>(initialValue)

  watch(paramsReactive, updatedParams => {
    router.replace({ query: toLocationQuery(updatedParams) })
  })

  return paramsReactive
}

export default useQueryParams
